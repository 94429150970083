import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { Avatar, Container, Button, Box, Center, Divider, ButtonGroupm, Flex, Spacer, Stack, HStack, VStack, StackDivider, SimpleGrid, Heading, extendTheme } from '@chakra-ui/react'
import { Icon, createIcon } from '@chakra-ui/react'
import { FaGithub, FaVimeo, FaLinkedin, FaPython, FaRust, FaLinux } from "react-icons/fa";
import { SiBlender } from "react-icons/si";
import { ReactComponent as ArtstationIcon } from './icons/ArtStation.svg';
import { ReactComponent as CaraIcon } from './icons/Cara.svg';
import { ReactComponent as HoudiniIcon } from './icons/Houdini.svg';
import { ReactComponent as GafferIcon } from './icons/Gaffer.svg';
import { ReactComponent as PythonIcon } from './icons/Python.svg';
import { ReactComponent as USDIcon } from './icons/USD.svg';
import "typeface-roboto";
import sample from './bg_video.mp4';
import jv from './avatar.jpg';

const colorsA = ["#2a5871", "#0d5c6e", "#005f66", "#00615a", "#1c624a"]; // https://mycolor.space/gradient?ori=to+right+top&hex=%232A5871&hex2=%231C624A&sub=1

// extending default theme to add custom font
const theme = extendTheme({
  fonts: {
    heading: `'Roboto'`,
    body: `'Roboto'`,
  },
})

function App() {
  let CreativeLinks = [
    {
      "icon": FaVimeo,
      "text": "Showreel",
      "url": "https://vimeo.com/937438326",
      "desc": "My 2024 showreel",
    },
    {
      "icon": ArtstationIcon,
      "text": "Artstation",
      "url": "https://www.artstation.com/kubo-von",
      "desc": "Portfolio of both personal and professional projects.",
    },
    {
      "icon": CaraIcon,
      "text": "Cara",
      "url": "https://cara.app/kubovon",
      "desc": "Portfolio of personal projects. But on a non-evil paltform.",
    },
    {
      "icon": FaGithub,
      "text": "Github",
      "url": "https://github.com/kubo-von",
      "desc": "Tool developent for Gaffer, Houdini. Experiments with Rust.",
    },
    {
      "icon": FaLinkedin,
      "text": "LinkedIn",
      "url": "https://www.linkedin.com/in/jakub-vondra-9264b45b/",
      "desc": "My LinkedIn profile.",
    },
  ];

  function makeButton(data, i) {
    return (
      <HStack spacing='1em'>
        <a href={data.url}>
          <Button colorScheme='teal' align="left" minWidth="10.5em" bg={colorsA[i]} p="1.5em" borderRadius='0.5em'>
            <HStack >
              <Icon as={data.icon} boxSize="2.5em" /> <Box textSize="1em">{data.text}</Box>
            </HStack>
            <Spacer />
          </Button>
        </a>
        <Box align="left" >{data.desc}</Box>
      </HStack>
    );
  }


  return (
    <ChakraProvider theme={theme}>
      <div className="App">

        <Box as="video" zIndex="-1" width="100vw" height="100vh" top="0" left="0" objectFit="cover" position="fixed" src={sample} type='video/mp4' autoPlay loop muted playsinline></Box>

        <Center color='white' minHeight="100vh"  >
          <Container minWidth="30vw" maxWidth="38em">

            <Box bg='blackAlpha.800' p="1em" color='gray.200' borderWidth='0px' borderRadius='2em 2em 0em 0em' backdropFilter='auto' backdropBlur='8px'>

              <HStack spacing={4} align='stretch' p="1em">
                <Avatar name='Jakub Vondra' size='2xl' src={jv} />
                <Box alignItems="left" justifyContent="space-between" p="0.5em">
                <Box p="0.6em"></Box>
                  <Heading fontSize="1.5em" textAlign='left'> Jakub Vondra </Heading>
                  <Heading fontSize="1em" textAlign='left'>Senior 3D Environment Artist / TD / Generalist</Heading>
                </Box>
              </HStack>
              <Box align='left' textAlign="justify" p="0.5em">
              I've been working in VFX and animation for more than 13 years in various roles, lately mostly as an Environment artist and TD. I enjoy solving both creative and technical problems. I work with Houdini, Blender and Python and I tinker with Gaffer and USD on the side. I've been also trying to get into programming with Rust, by writing a hobby renderer.                 </Box>
              <HStack spacing="1em" align='stretch' p="0.5em">
                <Spacer />
                <Icon as={HoudiniIcon} boxSize="2em" />
                <Icon as={GafferIcon} boxSize="2em" />
                <Icon as={SiBlender} boxSize="2em" />
                <Icon as={PythonIcon} boxSize="2em" />
                <Icon as={USDIcon} boxSize="2em" />
                <Icon as={FaRust} boxSize="2em" />
                <Spacer />
              </HStack>
            </Box>

            <Box bg='blackAlpha.600' p={8} color='gray.200' borderWidth='0px' borderRadius='0em 0em 2em 2em' backdropFilter='auto' backdropBlur='8px'>
              <Heading fontSize="1.5em" textAlign='left' p="0.5em"> Links </Heading>
              <VStack spacing={4} align='stretch'>
                {
                  CreativeLinks.map((currElement, index) => {
                    return makeButton(currElement, index);
                  })
                }

              </VStack>
            </Box>

          </Container>
        </Center>

      </div>

    </ChakraProvider>
  );
}

export default App;
